<template>
    <div id="PaymentForm">
        <form id="payment-form">
            <div id="payment-element">
                <!--Stripe.js injects the Payment Element-->
            </div>
            <button id="submit" @click="submitPayment">
                <div class="spinner hidden" id="spinner"></div>
                <span id="button-text">徳積みさせて頂く</span>
            </button>
            <div id="payment-message" class="hidden"></div>
        </form>
    </div>
</template>
<script>
export default {
    name: 'PaymentForm',
}
</script>
<script setup>
import { onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { ElLoading, ElMessage } from 'element-plus'
import { createPaymentIntent } from '@/service/api'
import { loadStripe } from '@stripe/stripe-js'

const store = useStore()
const props = defineProps({
    amountFormData: Object,
})

// loadStripe
let stripe, elements
const stripeKey = process.env.VUE_APP_STRIPE_KEY
onBeforeMount(async () => {
    let res = await loadStripe(stripeKey)
    stripe = res
    await startPayment()
})

async function startPayment() {
    let loadingInstance = ElLoading.service()
    try {
        await initStripe()
        await checkStatus()
        loadingInstance.close()
    } catch {
        loadingInstance.close()
        ElMessage.error('Some error occurred, please retry again later.')
    }
}

// stripeFunctions
async function initStripe() {
    let res = await createPaymentIntent(
        store.getters.getUserData,
        props.amountFormData.amount
    )
    let { clientSecret } = res.data
    const appearance = { theme: 'stripe' }
    elements = stripe.elements({ appearance, clientSecret, locale: 'ja' })
    const paymentElement = elements.create('payment')
    paymentElement.mount('#payment-element')
}
async function checkStatus() {
    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
    )
    if (!clientSecret) {
        return
    }
    const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
    if (paymentIntent.status === 'succeeded') {
        ElMessage.success('Payment succeeded!')
    } else if (paymentIntent.status === 'processing') {
        ElMessage.message('Your payment is processing.')
    } else if (paymentIntent.status === 'requires_payment_method') {
        ElMessage.error('Your payment was not successful, please try again.')
    } else {
        ElMessage.error('Something went wrong.')
    }
}
async function submitPayment(e) {
    e.preventDefault()
    let loadingInstance = ElLoading.service()
    const { error } = await stripe.confirmPayment({
        elements,
        confirmParams: {
            return_url: window.location.origin + '/donate-process/success',
        },
    })
    if (error.type === 'card_error' || error.type === 'validation_error') {
        ElMessage.error(error.message)
    } else {
        ElMessage.error('An unexpected error occurred.')
    }
    loadingInstance.close()
}
</script>
<style lang="scss" scoped>
/* Variables */
* {
    box-sizing: border-box;
}

#payment-form {
    margin: 0 auto;
}

form {
    width: 30vw;
    min-width: 500px;
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
        0px 2px 5px 0px rgba(50, 50, 93, 0.1),
        0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;
}

.hidden {
    display: none;
}

#payment-message {
    color: rgb(105, 115, 134);
    font-size: 16px;
    line-height: 20px;
    padding-top: 12px;
    text-align: center;
}

#payment-element {
    margin-bottom: 24px;
}

/* Buttons and links */
button {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
}
button:hover {
    filter: contrast(115%);
}
button:disabled {
    opacity: 0.5;
    cursor: default;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}
.spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}
.spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
}
.spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 600px) {
    form {
        width: 80vw;
        min-width: initial;
    }
}
</style>
